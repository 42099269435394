import * as React from "react";

import { makeTile } from "../component-helpers";
import TileSection from "../components/common/tile-section";
import Search from "../components/search";
import { Recording } from "../types";

export interface MainTemplateProps {
  someRecentRecordings: Recording[];
}

const MainPage: React.SFC<{ pathContext: MainTemplateProps }> = ({
  pathContext
}) => (
  <div className="papio-children">
    <Search />
    <TileSection
      seeMoreLink="/recent/"
      title="Recent"
      tiles={pathContext.someRecentRecordings.map(makeTile)}
    />
  </div>
);

export default MainPage;
